import { useCallback } from 'react';
import { Form, Formik } from 'formik';

import SaveIcon from '@mui/icons-material/Save';
import LoadingButton from '@mui/lab/LoadingButton';
import Button from '@mui/material/Button';
import DialogActions from '@mui/material/DialogActions';
import Typography from '@mui/material/Typography';
import DialogContent from 'components/DialogContent';
import DialogTitle from 'components/DialogTitle';
import PaymentFormFields, {
  Fields,
  schema,
} from 'components/PaymentFormFields';

import { ClubInvoiceView, ClubInvoiceViewPaymentDetails } from 'schema';
import { useDialog } from 'components/Dialogs';
import { useUpdateInvoicePayment } from 'features/Invoice';

type Props = {
  invoice: ClubInvoiceView;
  payment: ClubInvoiceViewPaymentDetails;
};

const EditPaymentForm = ({ invoice, payment }: Props) => {
  const [, { close }] = useDialog('paymentEdit');
  const { mutate, isPending } = useUpdateInvoicePayment(invoice.id, payment.id);

  const handleSubmit = useCallback(
    ({ amount, ...rest }: Fields) => {
      const form = {
        amount: Number(amount),
        ...rest,
      };

      mutate(form, {
        onSuccess: close,
      });
    },
    [close, mutate]
  );

  const initial = {
    amount: payment.amount.toString(),
    comment: payment.comment ?? '',
  };

  return (
    <Formik<Fields>
      initialValues={initial}
      onSubmit={handleSubmit}
      validationSchema={schema}>
      <Form>
        <DialogTitle onClose={close}>Mokėjimas</DialogTitle>

        <DialogContent>
          <Typography sx={{ mb: 2 }}>
            Sąskaita: {invoice.invoiceNumber}
          </Typography>

          <PaymentFormFields payment={payment} />
        </DialogContent>

        <DialogActions>
          <Button onClick={close} variant="text">
            Atšaukti
          </Button>

          <LoadingButton
            loading={isPending}
            variant="contained"
            type="submit"
            startIcon={<SaveIcon />}>
            Saugoti
          </LoadingButton>
        </DialogActions>
      </Form>
    </Formik>
  );
};

export default EditPaymentForm;
