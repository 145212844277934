import LinearProgress from '@mui/material/LinearProgress';
import Link from '@mui/material/Link';

import { useClub } from 'components/ClubProvider';
import { useDownload } from 'lib';
import { CoachRegistrationOrderAttendant } from 'schema';

type Props = {
  attendant: CoachRegistrationOrderAttendant;
};

const SessionAttendantDialogAgreement = ({ attendant }: Props) => {
  const { order } = attendant;

  const [download, isDownloading] = useDownload(
    `/api/v1/clubs/${useClub().id}/orders/${order.id}/attendants/${
      attendant.id
    }/agreement`
  );

  if (!attendant.agreement) return null;

  if (isDownloading) return <LinearProgress variant="indeterminate" />;

  return (
    <Link onClick={download}>{attendant.agreement.fileNameForDownload}</Link>
  );
};

export default SessionAttendantDialogAgreement;
