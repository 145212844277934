import { useCallback, useState, MouseEvent } from 'react';
import axios, {
  AxiosError,
  AxiosRequestConfig,
  AxiosResponse,
  RawAxiosRequestHeaders,
} from 'axios';
import { saveAs } from 'file-saver';

import { ENV, STORAGE_ACCESS_TOKEN } from 'const';
import { useNotification } from 'components/Notifications';
import { ApiErrorResponse, authRefreshToken } from 'schema';
import { intercept, error } from '@igudo/debugger';

type Subscriber = (ok: boolean) => void;

type FilesMap = {
  [key: string]: File | File[] | null;
};

export type UploadField = {
  currentUrl: string | null;
  uploaded: File | null;
};

const http = axios.create({
  headers: { 'Content-Type': 'application/json' },
});

const expiredMessage = 'expired_token';
let isRefreshing = false;
let subscribers: Subscriber[] = [];

function onRefreshed(ok: boolean) {
  subscribers.forEach(cb => cb(ok));
}

function subscribeTokenRefresh(cb: Subscriber) {
  subscribers.push(cb);
}

http.interceptors.request.use(
  config => {
    const token = localStorage.getItem(STORAGE_ACCESS_TOKEN);
    if (!token) return config;

    config['headers'] = config.headers ?? {};

    (config.headers as RawAxiosRequestHeaders)['Authorization'] =
      `Bearer ${token}`;

    return config;
  },
  error => Promise.reject(error)
);

if (ENV !== 'production') {
  http.interceptors.response.use(intercept, error);
}

http.interceptors.response.use(
  (response: AxiosResponse<any, any>) => response,

  (error: any) => {
    const originalRequest = error.config as AxiosRequestConfig & {
      _retry?: boolean;
    };

    if (!originalRequest) return;

    if (
      error.response.status === 401 &&
      error.response.data?.errorCode === expiredMessage &&
      !originalRequest._retry
    ) {
      const result = new Promise((resolve, reject) => {
        subscribeTokenRefresh((ok: boolean): void => {
          if (!ok) {
            reject(error);
          } else {
            const token = localStorage.getItem(STORAGE_ACCESS_TOKEN);

            if (!token) return reject('No token');

            if (!originalRequest.headers) {
              originalRequest.headers = {};
            }

            originalRequest.headers['Authorization'] = `Bearer ${token}`;

            originalRequest._retry = true;
            resolve(axios(originalRequest));
          }
        });
      });

      if (!isRefreshing) {
        isRefreshing = true;

        authRefreshToken()
          .then(({ accessToken }) => {
            localStorage.setItem(STORAGE_ACCESS_TOKEN, accessToken);

            isRefreshing = false;
            onRefreshed(true);
            subscribers = [];
          })
          .catch(() => {
            onRefreshed(false);
          });
      }

      return result;
    }

    return Promise.reject(error);
  }
);

export function useErrorHandler(defaultMessage: string = 'Įvyko klaida') {
  const { pop } = useNotification();

  return (error: AxiosError<ApiErrorResponse<null, unknown>>) => {
    const responseMessage = error.response?.data.message;

    if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') {
      console.error(error);
    }

    pop(
      responseMessage
        ? responseMessage
        : error.message
          ? error.message
          : defaultMessage
    );
  };
}

type UseDownload = [(event: MouseEvent<HTMLElement>) => void, boolean];

// TODO refactor useDownload to take exported function from the schema same way
// react query does it
export function useDownload(url: string, params: any = null): UseDownload {
  const [loading, setLoading] = useState(false);
  const handleError = useErrorHandler();

  const download = useCallback(
    (event: MouseEvent<HTMLElement>) => {
      event.preventDefault();
      event.stopPropagation();

      if (loading) return;

      setLoading(true);

      http
        .get(url, { responseType: 'blob', params })
        .then(response => {
          setLoading(false);

          const fileName =
            response.headers['content-disposition']?.split('filename=')[1];

          saveAs(response.data, fileName);
        })
        .catch(error => {
          setLoading(false);
          handleError(error);
        });
    },
    [handleError, loading, url, params]
  );

  return [download, loading];
}

export function makeFormData(data: unknown, files: FilesMap | null) {
  const formData = new FormData();

  formData.append('data', JSON.stringify(data));

  if (files) {
    Object.keys(files).forEach(name => {
      const file = files[name];

      if (file) {
        if (Array.isArray(file)) {
          file.forEach(item => {
            formData.append(`${name}[]`, item);
          });
        } else {
          formData.append(name, file);
        }
      }
    });
  }

  return formData;
}

export default http;
