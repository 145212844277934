import { useCallback } from 'react';

import DeleteIcon from '@mui/icons-material/Delete';
import Box from '@mui/material/Box';
import TableCell from '@mui/material/TableCell';
import Typography from '@mui/material/Typography';
import ConfirmableIconButton from 'components/ConfirmableIconButton';
import EuroPrice from 'components/EuroPrice';
import InvoiceDownload from 'components/InvoiceDownload';
import InvoiceSendButton from 'components/InvoiceSendButton';
import InvoiceSyncStatus from 'components/InvoiceSyncStatus';
import SelectableTableRow, {
  SelectableTableRowProps,
} from 'components/SelectableTableRow';

import { useMe } from 'components/AuthGuard';
import { useDeleteInvoice } from 'features/Invoice';
import { formatDate, formatDateTime, useNavigateRow } from 'lib';
import { ClubInvoiceIndexParams, ClubInvoiceListView } from 'schema';

type Props = SelectableTableRowProps & {
  invoice: ClubInvoiceListView;
  params: ClubInvoiceIndexParams;
};

const InvoicesTableRow = ({ invoice, params, ...rest }: Props) => {
  const me = useMe();
  const { mutate } = useDeleteInvoice(invoice.id, params);

  const {
    id,
    user,
    isPeriodic,
    period,
    total,
    discount,
    invoiceNumber,
    createdDate,
    sentAt,
    payment,
    attendants,
    isLocked,
    isPaid,
    hasPdf,
  } = invoice;

  const handleDelete = useCallback(() => {
    mutate();
  }, [mutate]);

  return (
    <SelectableTableRow
      {...useNavigateRow(id)}
      {...rest}
      sx={{
        cursor: 'pointer',
        '&:last-child td': {
          borderBottomWidth: 1,
        },
      }}>
      <TableCell>
        <InvoiceSyncStatus invoice={invoice} />
      </TableCell>

      <TableCell>
        <Typography>{user.fullName}</Typography>

        <Typography variant="body2" color="text.secondary">
          {attendants}
        </Typography>
      </TableCell>

      <TableCell align="center">
        {isPeriodic ? 'Periodinis' : 'Registracija'}
      </TableCell>

      <TableCell align="center">{period}</TableCell>

      <TableCell align="center">
        <EuroPrice>{total}</EuroPrice>
      </TableCell>

      <TableCell align="center">
        {discount ? <EuroPrice>{discount}</EuroPrice> : '--'}
      </TableCell>

      <TableCell align="center">
        {hasPdf ? <InvoiceDownload invoice={invoice} /> : invoiceNumber}
      </TableCell>

      <TableCell align="center">{formatDateTime(createdDate)}</TableCell>
      <TableCell align="center">{sentAt && formatDateTime(sentAt)}</TableCell>
      <TableCell align="center">{isPaid && payment?.amount}</TableCell>

      <TableCell align="center">
        {payment?.paidAt && formatDate(payment.paidAt)}
      </TableCell>

      <TableCell align="right">
        <Box sx={{ display: 'flex' }}>
          <InvoiceSendButton invoice={invoice} />

          {me.isAdmin && !isLocked && (
            <ConfirmableIconButton
              text={`Ar tikrai norite ištrinti ${invoice.invoiceNumber}`}
              onConfirm={handleDelete}>
              <DeleteIcon fontSize="small" color="error" />
            </ConfirmableIconButton>
          )}
        </Box>
      </TableCell>
    </SelectableTableRow>
  );
};

export default InvoicesTableRow;
